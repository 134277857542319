<template>
    <b-card
      :title="prvCompetition.name"
    >
      <b-card-body>
        <div>Cette compétition aura lieu <span v-html="period"></span> à <b>{{ prvCompetition.location }}</b></div>.
        <div v-if="prvCompetition.registrationMode == 'S'">
          La participation à cette compétition est réservée aux compétiteurs "qualifiés"
          <template v-if="prvCompetition.isFederal">
            <u>à la seule condition qu'ils soient en règle au niveau de leur licence</u> au premier jour de la compétition (licence à jour pour l'année {{ prvCompetition.year}} avec option "carte compétition" valide).
          </template>
        </div>
        <div v-else-if="prvCompetition.registrationMode == 'R'">
          La participation à cette compétition est soumise à pré-inscription (à faire auprès de l'organisateur).
          <template v-if="prvCompetition.isFederal">
            Pour rappel, <span v-if="prvCompetition.isOpen">sauf pour les compétiteurs "open", </span><u>les candidats devront être en règle au niveau de leur licence</u> au premier jour de la compétition (licence à jour pour l'année {{ prvCompetition.year}} avec option "carte compétition" valide).
          </template>
        </div>
        <div v-else-if="prvCompetition.registrationMode == 'C'">
          La participation à cette compétition est prioritairement réservée aux compétiteurs "qualifiés". Néanmoins, il est possible à des compétiteurs non-pré-qualifiés de participer en se pré-inscrivant auprès de l'organisateur.
          <div>
            <template v-if="prvCompetition.isFederal">
              <template v-if="prvCompetition.isOpen">
                Hormis pour les compétiteur "open", <u>t</u>
              </template>
              <template v-else><u>T</u></template>
              <u>ous les candidats devront être en règle au niveau de leur licence</u> au premier jour de la compétition (licence à jour pour l'année {{ prvCompetition.year }} avec option "carte compétition" valide).
            </template>
          </div>
        </div>
        <div v-if="prvCompetition.registrationMode == 'N'">
          Cette compétition est ouverte à tous les licenciés en règle (à jour de leur licence {{ prvCompetition.year}} avec option "carte compétition") et ne requiert pas de pré-inscription.
        </div>
        <div v-if="prvCompetition.isOpen">
            Cette compétition est prévue d'être "open", ce qui signifie qu'elle acceptera également des compétiteurs non-licenciés (à la FFVL) ou des licensiées n'étant pas à jour de leur licence au premier jour de la compétition. Elle fera donc l'objet de 2 classements (un classement général et un classement fédéral). Pour rappel, seuls les compétiteurs en règle (à jour de leur licence {{ prvCompetition.year}} avec option "carte compétition" valide) seront pris en considération dans le classement "fédéral" afin de prétendre à un éventuel titre ou une éventuelle qualification.
        </div>
        <div v-if="prvCompetition.isOpen && prvCompetition.registrationMode == 'S'">
            Compte-tenu que cette compétition est théoriquement réservée au seuls qualifiés, les compétiteurs désirant participer en "open" sont priés de se faire connaître dès que possible auprès de l'organisateur."
        </div>
        <div v-if="prvCompetition.isOpen && prvCompetition.registrationMode == 'R'">
          Les candidats souhaitant participer en "open" doivent également se faire connaître auprès de l'organisateur en se pré-inscrivant.
        </div>

        <!--<last-update v-model="prvLastUpdate" @refresh="refresh" />-->
      </b-card-body>
    </b-card>
  
  </template>
  
  <script>
  //import LastUpdate from '@/components/LastUpdate.vue';
  //import InfoHelper from '@/components/InfoHelper.vue';
  
  //import axios from "axios";
  //import SynchroHelper from "@/services/helpers/synchroHelper";
  //import { RST_COMPETITION } from "@/LocalDB";
  
  export default
  {
    components: { /*LastUpdate,*/ /*InfoHelper*/ },
    props: {
      value: {type: Object, required: true},
      lastUpdate: { type: Date, required: true},
    },
    data() {
      return {
        months: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
        days: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
        prvCompetition: this.value,
        prvLastUpdate: this.lastUpdate,
      }
    },
    computed: {
        period(){
        console.log('Period for completed', this.prvCompetition);
        if(this.prvCompetition.predicted_end_date == this.prvCompetition.predicted_start_date)
            return "le <b>" + this.getDate(this.prvCompetition.predicted_start_date) + "</b>";
        else
            return "du <b>" + this.getDate(this.prvCompetition.predicted_start_date, this.prvCompetition.predicted_end_date) + "</b> au <b>" + this.getDate(this.prvCompetition.predicted_end_date) + "</b>";
      },
    },
    methods: {
        getDate(date, endOfPeriode = null){
        var d =  new Date(date);
        var dddd = this.days[d.getDay()];
        var mmmm = this.months[d.getMonth()];
        var dd = d.getDate();
        dd = (dd == 1) ? "1er" : ((dd < 10) ? "0" + dd: dd);
        var yyyy = d.getFullYear();

        if(endOfPeriode && endOfPeriode != null){
            var d2 = new Date(endOfPeriode);
            if(d2.getFullYear() == d.getFullYear()){
                if(d2.getMonth() == d.getMonth())
                    return dddd + " " + dd;
                else
                    return dddd + " " + dd + " " + mmmm;
            }
        }
        return dddd + " " + dd + " " + mmmm + " " + yyyy;
      },
            refresh(){
      /*    this.$showRefresher()
            .then(() => {
                SynchroHelper.refreshLinkedCompetition(this.value, this.$hideRefresher);
                this.$hideRefresher();
            });*/
      }
    },
    mounted(){
      this.refresh();
    }
  }
  </script>
  