<template>
    <div v-if="!isAuthenticated || userOnlyHaveOneOfRoles([Roles.Public, Roles.Tester])">
        <slot></slot>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed:{
    ...mapGetters('auth', ['isAuthenticated', 'userOnlyHaveOneOfRoles']),
  }
}
</script>
