<template>
  <b-card
    :title="prvCompetition.name"
  >
    <b-card-body>
      <p>Cette compétition se déroule en ce moment à <b>{{ prvCompetition.location }}</b><!-- et compte <b>{{competition.nbCategories}}</b> {{(competition.nbCategories > 1) ? 'catégories' : 'catégorie'}}-->.
      <br/>
      <b>{{prvCompetition.nbRounds}}</b> {{ (prvCompetition.nbRounds > 1) ? 'manches sont prévues.' : 'manche est prévue.' }}</p>
      <div>
        <div v-for="field in prvCompetition.fields" :key="field.field_number" class="field" :class="{'hot': field.isHot, 'cold': !field.isHot}">
          <div>{{ field.field_name }}<span v-if="prvCompetition.fields.length > 1" style="float:right">N° {{ field.field_number}}</span></div>
          <div v-if="field.isHot || field.event_name != null" class="warning">ATTENTION ! Ce terrain est chaud !
            <info-helper>
              {{ $t("definitions.hot_field") }}
              <br/><br/>Le terrain est dit "chaud" lorsqu'une épreuve est en cours de réalisation, ou même simplement de préparation, sur celui-ci.<br/>
              Généralement cette phase démarre à l'arrivée des juges ou du directeur de terrain et s'arrête lorsque le dernier de ceux-ci a quitté le terrain.<br/>
              Pendant toute cette phase, il est interdit d'entrer ou sortir du terrain sans une autorisation expresse du directeur de terrain.
            </info-helper>
          </div>
          <div v-else>Ce terrain est froid</div>
          <div v-if="field.event_name != null">
            <p>En ce moment, sur ce terrain, se déroule l'épreuve de <b>{{ field.event_longname}}</b> de la <b>manche {{ field.round_number }}</b> pour la catégorie <b>{{ field.category_longname }}</b> niveau <b>{{ field.level_longname }}</b></p>
            <h4>{{ field.current_competitor_name }}</h4>
            <p>{{ (field.current_compulsory_id != null) ? ` est en train de réaliser sa figure ${field.current_compulsory_code}-${field.current_compulsory_name}` : (field.current_step != field.event_name ? ( field.current_step == '' ? 'vient de terminer son épreuve' : ` présente ${field.current_step == 'routine' ? 'sa' : 'son'} ${field.current_step}`) : ' est en train de passer' )}}</p>
          </div>
        </div>
      </div>
      <div>
        <div v-for="round in prvCompetition.rounds" :key="round.id" class="round">
          <h5>Manche {{ round.round_number }}</h5>
          <div v-for="level in round.levels" :key="level.id" class="level">
            <div v-for="category in level.category" :key="category.id" class="category">
            </div>
          </div>
        </div>
      </div>
      <div>
        <slot name="bottom-area" v-bind:competition_id="prvCompetition.id"></slot>
      </div>
      <last-update v-model="prvLastUpdate" @refresh="refresh" />
    </b-card-body>
  </b-card>

</template>

<script>
import LastUpdate from '@/components/LastUpdate.vue';
import InfoHelper from '@/components/InfoHelper.vue';

//import axios from "axios";
//import SynchroHelper from "@/services/helpers/synchroHelper";
//import { RST_COMPETITION } from "@/LocalDB";

export default
{
  components: { LastUpdate, InfoHelper },
  props: {
    value: {type: Object, required: true},
    lastUpdate: { type: Date, required: true},
  },
  data() {
    return {
      prvCompetition: this.value,
      prvLastUpdate: this.lastUpdate,
    }
  },
  computed: {
    predictedIntro(){
      return "";
    },
  },
  methods: {
    refresh(){
    /*    this.$showRefresher()
          .then(() => {
              SynchroHelper.refreshLinkedCompetition(this.value, this.$hideRefresher);
              this.$hideRefresher();
          });*/
    }
  },
  mounted(){
    this.refresh();
  }
}
</script>
