<template>
    <b-card
      :title="prvCompetition.name"
    >
      <b-card-body>
        <p v-html="message"></p>
        <div>
          <slot name="bottom-area" v-bind:competition_id="prvCompetition.id"></slot>
        </div>
      </b-card-body>
    </b-card>
    </template>
  
  <script>
 
  export default
  {
    props: {
      value: {type: Object, required: true},
      lastUpdate: { type: Date, required: true},
    },
    data() {
      return {
        prvCompetition: this.value,
        prvLastUpdate: this.lastUpdate,
      }
    },
    computed: {

      message(){
        var rounds = this.$tc("completed-competition-card.rounds", this.prvCompetition.nbRounds);
        var open = this.prvCompetition.isOpen ? this.$t("completed-competition-card.open") : "";
        return this.$t("completed-competition-card.message", {
          round: rounds,
          open: open,
          period: this.period,
          location: this.prvCompetition.location
        });
      },
      period(){
        var from = new Date(this.prvCompetition.predicted_start_date);
        if(this.prvCompetition.predicted_end_date == this.prvCompetition.predicted_start_date)
          return this.$t('period.on-bold', {date : this.$d(from, 'longDay')});
        else{
            var to = new Date(this.prvCompetition.predicted_end_date);
            return this.$t('period.range-bold', { from: this.$d(from, 'longDay'), to: this.$d(to, 'longDay')})
        }
      },
    },
  }
  </script>
  