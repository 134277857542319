<template>
    <div class="container">
      <!-- DASHBOARD "PUBLIC" -->
      <public-area>
        <!-- DASHBOARD MODE APPLICATION MOBILE POUR PROFILE PUBLIC -->
        <mobile-app-area>
          <div>
              <p>
                <language-selector style="float:right;"></language-selector>
                {{ $t('home.app_welcome_message') }}<br/>
                {{ $t('home.app_description_message') }}
              </p>
          </div>
        </mobile-app-area>

        <!-- DASHBOARD MODE WEB POUR PROFILE PUBLIC -->
        <web-area>
          <language-selector style="float:right;"></language-selector>
          <p>{{ $t('home.web_welcome_message') }}</p>
        </web-area>
      </public-area>

      <authenticated-area hideWhenAccessIsRejected>
        <template #authentification-required>
          <p class="info">{{ $t("home.web_login_invite_message_pre") }}<b-link @click="showLoginDialog()">{{ $t("home.web_login_invite_action") }}</b-link>{{ $t("home.web_login_invite_message_post")}}</p>
        </template>
      </authenticated-area>

      <div v-if="predicted.length == 0 && running.length == 0">
        <b>{{ $t("home.no_running_or_predicted_competition") }}</b>
      </div>
      <collapse-panel v-if="running.length > 0" :visible="runningCompetitionsExpanded" @expand="runningCompetitionsExpanded=true" @collapse="runningCompetitionsExpanded=false">
        <template #title>
          <h4>{{ $tc("home.running_competition_title", running.length) }} <br/>bou :Compétition{{ running.length > 1 ? "s" : ""}} en cours</h4>
        </template>;
        <p>{{ $tc("running_competition_counter_message", running.length) }}<br/>bou :Il y a {{ running.length }} compétition{{ (running.length > 1) ? "s" : "" }} se déroulant en ce moment.</p>
        <running-competition-card
          v-for="competition in running"
          :key="competition.id"
          :value="competition"
          :lastUpdate="lastUpdate"
        >
          <template #bottom-area="cardProps">
            <b-button class="secondary" @click="viewRunningCompetitionDetails(cardProps.competition_id)">Détails & résultats intermédiaires</b-button>
          </template>
        </running-competition-card>
      </collapse-panel>

      <collapse-panel v-if="predicted.length > 0" :visible="predictedCompetitionsExpanded" @expand="predictedCompetitionsExpanded=true" @collapse="predictedCompetitionsExpanded=false">
        <template #title>
          <h4>{{ $tc("home.upcoming_competition_title", predicted.length)}}</h4>
        </template>
        <p>{{ $tc("home.upcoming_competition_counter_message", predicted.length) }}</p>
        <upcoming-competition-card
          v-for="competition in predicted"
          :key="competition.id"
          :value="competition"
          :lastUpdate="lastUpdate"
        >
        </upcoming-competition-card>
      </collapse-panel>

      <collapse-panel v-if="completed.length > 0" :visible="completedCompetitionsExpanded" @expand="completedCompetitionsExpanded=true" @collapse="completedCompetitionsExpanded=false">
        <template #title>
          <h4>{{ $tc("home.last_completed_competition_title", completed.length) }}</h4>
        </template>
        <p>{{ $tc("home.last_completed_competition_counter_message", completed.length) }}</p>
        <completed-competition-card
          v-for="competition in completed"
          :key="competition.id"
          :value="competition"
          :lastUpdate="lastUpdate"
        >
        <template #bottom-area="cardProps">
            <b-button class="success" @click="viewRunningCompetitionDetails(cardProps.competition_id)">{{ $t("home.view_results_button") }}</b-button>
          </template>
        </completed-competition-card>
      </collapse-panel>

      <collapse-panel v-if="missed.length > 0" :visible="missedCompetitionsExpanded" @expand="missedCompetitionsExpanded=true" @collapse="missedCompetitionsExpanded=false">
        <template #title>
          <h4>{{  $tc("home.missing_competition_title", missed.length) }}</h4>
        </template>
        <p>{{ $tc("home.missing_competition_counter_message", missed.length) }}</p>
        <missed-competition-card
          v-for="competition in missed"
          :key="competition.id"
          :value="competition"
          :lastUpdate="lastUpdate"
        >
        </missed-competition-card>
      </collapse-panel>
      <!-- FIN DE DASHBOARD MODE WEB POUR PROFILE PUBLIC -->


      <login-dialog ref="loginDialog">
      </login-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import AuthenticatedArea from '@/components/Security/AuthenticatedArea.vue';
import { RST_COMPETITION } from "@/LocalDB";
import { Capacitor } from "@capacitor/core";
import MobileAppArea from "@/components/MobileAppArea";
import WebArea from '@/components/WebArea.vue';
import PublicArea from '@/components/PublicArea.vue';
import axios from "axios";
import UpcomingCompetitionCard from '@/components/HomePage/UpcomingCompetitionCard';
import MissedCompetitionCard from '@/components/HomePage/MissedCompetitionCard';
import RunningCompetitionCard from '@/components/HomePage/RunningCompetitionCard';
import CompletedCompetitionCard from '@/components/HomePage/CompletedCompetitionCard';
import LoginDialog from '@/components/Dialogs/LoginDialog';
import CollapsePanel from '../components/Panels/CollapsePanel.vue';
import LanguageSelector from "../components/LanguageSelector.vue";

export default {
  components: { LanguageSelector, AuthenticatedArea, MobileAppArea, WebArea, PublicArea, UpcomingCompetitionCard, MissedCompetitionCard, RunningCompetitionCard, CompletedCompetitionCard, LoginDialog, CollapsePanel },
    data(){
      return{
          predicted: [],
          missed: [],
          running: [],
          completed: [],
          runningAndRecentCompetitionsList: [],
          lastUpdate: null,
          lastUpdateTimerID: null, 
        }
    },
    computed:{
        ...mapGetters('config', [ 'cfgHomePredictedCompetitionsExpandes', 'cfgHomeMissedCompetitionsExpandes', 'cfgHomeRunningCompetitionsExpandes', 'cfgHomeCompletedCompetitionsExpandes']),
        isNativeApp(){ return Capacitor.isNativePlatform() },
        platform(){ return Capacitor.getPlatform();},
        canGoToCategory() { return false; },
        canGoToRound(){ return false},
        canGoToSetOfRound() { return false},
        competitionsRunning(){ return RST_COMPETITION.query().where("isStarted", true).where("isCompleted", false).get(); },

        runningCompetitionsExpanded:{
          get: function() { return this.cfgHomeRunningCompetitionsExpandes},
          set: function(newVal) { this.setCfgHomeRunningCompetitionsExpandes(newVal);}
        },
        predictedCompetitionsExpanded:{
          get: function() { return this.cfgHomePredictedCompetitionsExpandes},
          set: function(newVal) { this.setCfgHomePredictedCompetitionsExpandes(newVal);}
        },
        missedCompetitionsExpanded:{
          get: function() { return this.cfgHomeMissedCompetitionsExpandes},
          set: function(newVal) { this.setCfgHomeMissedCompetitionsExpandes(newVal);}
        },
        completedCompetitionsExpanded:{
          get: function() { return this.cfgHomeCompletedCompetitionsExpandes},
          set: function(newVal) { this.setCfgHomeCompletedCompetitionsExpandes(newVal);}
        },
      },
    methods:{
        ...mapActions('synchro', ['checkForUpdates']),
        ...mapMutations('config', ['setCfgHomePredictedCompetitionsExpandes', 'setCfgHomeMissedCompetitionsExpandes', 'setCfgHomeRunningCompetitionsExpandes', 'setCfgHomeCompletedCompetitionsExpandes']),
        ...mapMutations('current', ['setViewedCompetitionId']),
        launchClick(){
            this.startSet();
            this.$router.push({ name:'Figure'} );
        },
        goToRound(){
            this.$router.push({ name: 'Round'} );
        },
        goToCategory(level, category){
            this.setCurrentLevelId(level.level);
            this.setCurrentCategoryId(category.category);
            this.$router.push({ name: 'Category'} );
        },
        async refreshRecentAndRunningCompetitionsList(){
          await this.$showRefresher();
          var url = this.getUrl('/api/getRunningAndRecentCompetitionsList.php');
          try{
            console.log('isTestMode', this.isTestMode);
            var response = await axios.post(url, { isTestMode: this.isTestMode, includePredicted: true, includeMissed: true, includeRunning: true, includeCompleted: true });
            this.predicted = response.data.predicted;
            this.missed = response.data.missed;
            this.running = response.data.running;
            this.completed = response.data.completed;
            this.lastUpdate = new Date();
          }
          catch(error){
            console.log('Error on refreshRecentAndRunningCompetitionsList', error);
          }
          this.$hideRefresher();
        },
        showLoginDialog(){
          this.$refs["loginDialog"].show();
        },
        viewRunningCompetitionDetails(competition_id){
          console.log('viewRunningCompetitionDetails', competition_id);
          this.setViewedCompetitionId(competition_id);
          this.$router.push({name: 'HomeRunningCompetitionDetailsView'});
        }
    },
    mounted(){
      this.refreshRecentAndRunningCompetitionsList();
      this.checkSynchro();
      this.$hideLoader();
   },
    destroy(){
      console.log('destroy');
      if(this.lastUpdateTimer != null)
        clearTimeout(this.lastUpdateTimerID);
    }
}
</script>

<style scoped>
    .action{
        min-height:100px;
        height:100px;
        min-width:150px;
        border-radius:5px;
        border:saddlebrown 1px solid;
        color:saddlebrown;
        text-decoration: none;
        margin: 2rem 1rem;

    }
    .action.main{
        border-color:green;
        border-width: 2px;
        color: green;
        font-weight:bolder;
    }
    .primary{
        min-width:150px;
        border-radius:5px;
        border:green 2px solid;
        color: green;
        font-weight:bolder;
        margin: 2rem 1rem;
    }
    .secondary{
        min-width:150px;
        border-radius:5px;
        border:saddlebrown 1px solid;
        color:saddlebrown;
        text-decoration: none;
        margin: 2rem 1rem;
    }
    .danger{
        min-width:150px;
        border-radius:5px;
        border:red 2px solid;
        color: red;
        margin: 2rem 1rem;
    }
</style>
