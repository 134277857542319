<template>
    <div class="locale-changer">
        {{ $t("language-selector.title") }} 
        <select v-model="selectedLocale">
            <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
                {{ $t(`language-selector.${lang}`) }}
            </option>
        </select>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: 'locale-changer',
  data () {
    return { 
        langs: ['fr', 'en'],
    }
  },
  computed:{
    ...mapGetters("config", ["locale"]),
    selectedLocale : {
        get(){ return this.locale; },
        set(newValue)
        { 
            this.setLocale(newValue); 
            this.$root.$i18n.locale = newValue;
        }
    }
  },
  methods:{
    ...mapMutations("config", ["setLocale"]),
  }
}
</script>