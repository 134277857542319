<template>
  <div>
    <div v-if="debugMode">
      <p><b><i>SecuredArea</i></b> => isAuthenticated : {{ isAuthenticated}}, authenticationRequired : {{ authenticationRequired}}</p>
      <!--<p>{{ operations }} </p>-->
    </div>
    <div v-if="isAuthenticated || !authenticationRequired">
      <div v-if="debugMode"><i>Authentifié ou authentification non nécessaire</i></div>
      <slot></slot>
    </div>
    <div v-else-if="!isAuthenticated && authenticationRequired">
        <slot name="authentification-required">
          <authentification-required />
        </slot>
        <slot name="others"></slot>
    </div>
    <div v-else>
        <slot name="access-denied">
          <access-denied v-if="!hideWhenAccessIsRejected" />
        </slot>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AccessDenied from "@/components/Security/AccessDenied.vue";

export default {
  name: 'SecuredArea',
  components: { AccessDenied },
  props: { hideWhenAccessIsRejected: { type: Boolean, default: false},
           debugMode: { type: Boolean, default:false},
           authenticationRequired: {type:Boolean, default: true},
         },
  data(){
    return {
      isAuthorized: false,
    }
  },
  computed:{
    ...mapGetters('auth', ['isAuthenticated', 'userHasOneOfRoles']),
  },
  methods:{
    ...mapActions('auth', ['userCan', 'userCanOneOfAsync']),
/*    async isAuthorizedAsync(){
      console.log('checking isAuthorizedAsync');
      if((this.roles == null || this.roles.length == 0) && (this.operations == null || this.operations.length == 0)){
        if(this.debugMode) console.log('=> returns isAuthenticatd :' + this.isAuthenticated);
        return this.isAuthenticated;
      }
      if(this.roles != null && this.roles.length > 0 && this.userHasOneOfRoles(this.roles))
      {
        if(this.debugMode) console.log('=> check done vs roles [' + this.roles + '] : true');
        return true;
      }
      if(this.operations != null && this.operations.length > 0){
        var check = await this.userCanOneOfAsync(this.operations);
        if(this.debugMode) {
          console.log('=> check done vs operations [' + this.operations + '] : ' + check);
        }
        return check;
      }
      console.log('default return');
      return false;
    },*/
    async refreshAuthorizationAsync(){
      if((this.roles == null || this.roles.length == 0) && (this.operations == null || this.operations.length == 0)){
        if(this.debugMode) console.log('=> returns isAuthenticatd :' + this.isAuthenticated);
        this.isAuthorized = (this.isAuthenticated || !this.authenticationRequired);
        return;
      }
      if(this.roles != null && this.roles.length > 0 && this.userHasOneOfRoles(this.roles))
      {
        if(this.debugMode) console.log('=> check done vs roles [' + this.roles + '] : true');
        this.isAuthorized = true;
        return;
      }
      if(this.operations != null && this.operations.length > 0){
        var check = await this.userCanOneOfAsync(this.operations);
        if(this.debugMode) {
          console.log('=> check done vs operations [' + this.operations + '] : ' + check);
        }
        this.isAuthorized = check;
        return;
      }
      console.log('default return');
      this.isAuthorized = false;
    }
  },
  async mounted(){
    await this.refreshAuthorizationAsync();
  }
}
</script>
