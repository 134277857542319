<template>
    <b-card
      :title="prvCompetition.name"
    >
      <b-card-body>
        <p v-html="$t('missed-competition-card.message', {period: period, location: prvCompetition.location})"></p>
      </b-card-body>
    </b-card>
  </template>
  
  <script>
  
  export default
  {
    props: {
      value: {type: Object, required: true},
      lastUpdate: { type: Date, required: true},
    },
    data() {
      return {
        prvCompetition: this.value,
        prvLastUpdate: this.lastUpdate,
      }
    },
    computed: {
      period(){
        var from = new Date(this.prvCompetition.predicted_start_date);
        if(this.prvCompetition.predicted_end_date == this.prvCompetition.predicted_start_date)
          return this.$t('period.on-bold', {date : this.$d(from, 'longDay')});
        else{
            var to = new Date(this.prvCompetition.predicted_end_date);
            return this.$t('period.range-bold', { from: this.$d(from, 'longDay'), to: this.$d(to, 'longDay')})
        }
      },
    },
  }
  </script>
  