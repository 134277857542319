<template>
  <div class="lastUpdate" v-if="date!=''">
    <b-icon icon="arrow-clockwise" size="sm" @click="$emit('refresh')" variant="primary" />&nbsp;
    Dernière mise à jour le : {{ date }}.
  </div>
</template>

<script>
export default {
  props: {
    value: { type: Date, default: null },
  },
  computed:{
    date(){
      return this.isDate(this.value)
                ? this.value.toLocaleDateString() + ' ' + this.value.toLocaleTimeString()
                : '';
    }
  },
  methods:{
    isDate(x){
      return (null != x) && !isNaN(x) && ("undefined" != typeof x.getDate);
    }
  }
}
</script>
